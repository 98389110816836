$main-color: #aaa;

.gh-checkout {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  visibility: hidden;
  z-index: 9999;

  &__iframe {
    background: white;
    height: 100%;
    max-width: 500px;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(100px);
    transition-duration: 0.2s;
    width: 100%;

    &__close {
      position: absolute;
      top: 18px;
      left: 23px;
      width: 31px;
      height: 31px;
      border-radius: 50%;
      background-color: white;
      border: 2px solid $main-color;
      z-index: 2;
      line-height: 40px;
      padding: 0;
      text-align: center;

      &:hover {
        cursor: pointer;
      }

      svg {
        font-size: 1.25rem;
        stroke: $main-color;
        fill: $main-color;
        vertical-align: baseline;
      }

      @media only screen and (min-width: 550px) {
        display: none;
      }
    }

    &--iframe {
      border: 0;
      font-size: 100%;
      font: inherit;
      height: 100%;
      margin: 0;
      outline: none;
      padding: 0;
      position: absolute;
      top: 0;
      width: 100%;
      vertical-align: baseline;
      z-index: 1;
    }

    &--loading {
      align-items: center;
      background: white;
      display: flex;
      height: 100%;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 20;

      &.loaded {
        display: none;
      }

      &__wrapper {
        text-align: center;
        width: 100%;

        &--loader {
          width: 50px;
          height: 50px;
          display: inline-block;
          padding: 0px;
          text-align: left;

          span {
            position: absolute;
            display: inline-block;
            width: 50px;
            height: 50px;
            border-radius: 100%;
            background: $main-color;
            -webkit-animation: loadingAnimation 1.5s linear infinite;
            animation: loadingAnimation 1.5s linear infinite;
          }

          span:last-child {
            animation-delay: -0.9s;
            -webkit-animation-delay: -0.9s;
          }

          @keyframes loadingAnimation {
            0% {
              transform: scale(0, 0);
              opacity: 0.8;
            }
            100% {
              transform: scale(1, 1);
              opacity: 0;
            }
          }

          @-webkit-keyframes loadingAnimation {
            0% {
              -webkit-transform: scale(0, 0);
              opacity: 0.8;
            }
            100% {
              -webkit-transform: scale(1, 1);
              opacity: 0;
            }
          }
        }
      }
    }
  }

  &__close {
    backdrop-filter: blur(6px);
    background: rgba(15, 15, 19, 0.8);
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &.opened {
    visibility: visible;

    .gh-checkout {
      &__iframe {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }

  &__messages {
    bottom: 40px;
    left: 40px;
    pointer-events: none;
    position: fixed;
    z-index: 9999;

    &--message {
      background: lighten(red, 46%);
      border: 1px solid lighten(red, 36%);
      border-radius: 12px;
      color: darken(red, 40%);
      display: block;
      max-width: 280px;
      opacity: 0;
      padding: 12px 20px;
      text-align: center;
      transition-duration: 0.1s;
      transform: translateX(-200px);
      width: 100%;
      visibility: hidden;

      p {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
          Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 14px;
        font-weight: 500;
        margin: 0;
      }
    }

    &.show {
      .gh-checkout {
        &__messages {
          &--message {
            opacity: 1;
            transform: translateX(0);
            visibility: visible;
          }
        }
      }
    }
  }
}
